"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = [{
  path: 'list',
  component: function component() {
    return import("./list");
  },
  meta: {
    title: '角色列表'
  }
}, {
  path: 'form',
  component: function component() {
    return import("./form");
  },
  meta: {
    title: '角色新增&编辑'
  }
}, {
  path: 'decoration',
  component: function component() {
    return import("./decoration");
  },
  meta: {
    title: '装修管理'
  }
}, {
  path: 'decoration/form',
  component: function component() {
    return import("./decoration-form");
  },
  meta: {
    title: '装修设置'
  }
}];
exports.default = _default;