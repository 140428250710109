"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = [{
  path: 'list',
  component: function component() {
    return import("./list");
  },
  meta: {
    title: '权益列表'
  }
}, {
  path: 'form/service-fee',
  component: function component() {
    return import("./form/service-fee");
  },
  meta: {
    title: '服务费权益编辑'
  }
}, {
  path: 'form/avatar-edging',
  component: function component() {
    return import("./form/avatar-edging");
  },
  meta: {
    title: '专属框权益编辑'
  }
}];
exports.default = _default;