"use strict";

module.exports = {
  title: '',

  /**
   * @type {boolean} true | false
   * @description Whether fix the header
   */
  fixedHeader: false,

  /**
   * @type {boolean} true | false
   * @description Whether show the logo in sidebar
   */
  sidebarLogo: true,

  /**
   * @type {boolean} true | false
   * @description 是否使用tagsView，开启时出现小标签，且根据路由配置 mata.keep = true 不自动销毁组件
   * { path: 'list', component: () => import('./list'), meta: { title: '角色列表', keep :true} }
   */
  tagsView: true
};