"use strict";

var _interopRequireWildcard = require("/home/gitlab-runner/builds/qGtedu_k/0/root/art_nft/dev-web/vue-admin/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard");

var _interopRequireDefault = require("/home/gitlab-runner/builds/qGtedu_k/0/root/art_nft/dev-web/vue-admin/node_modules/@babel/runtime-corejs2/helpers/interopRequireDefault");

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

require("/home/gitlab-runner/builds/qGtedu_k/0/root/art_nft/dev-web/vue-admin/node_modules/core-js/modules/es6.array.iterator.js");

require("/home/gitlab-runner/builds/qGtedu_k/0/root/art_nft/dev-web/vue-admin/node_modules/core-js/modules/es6.promise.js");

require("/home/gitlab-runner/builds/qGtedu_k/0/root/art_nft/dev-web/vue-admin/node_modules/core-js/modules/es6.object.assign.js");

require("/home/gitlab-runner/builds/qGtedu_k/0/root/art_nft/dev-web/vue-admin/node_modules/core-js/modules/es7.promise.finally.js");

var _vue = _interopRequireDefault(require("vue"));

var _loadSysConf = _interopRequireDefault(require("./loadSysConf"));

require("normalize.css/normalize.css");

var _elementUi = _interopRequireDefault(require("element-ui"));

require("element-ui/lib/theme-chalk/index.css");

require("@/styles/index.scss");

var _App = _interopRequireDefault(require("./App"));

var _store = _interopRequireDefault(require("./store"));

var _router = _interopRequireDefault(require("./router"));

var _lang = _interopRequireDefault(require("./lang"));

require("@/icons");

require("@/permission");

var _index3 = _interopRequireDefault(require("@/directive/index.js"));

var _global = _interopRequireDefault(require("@/components/global"));

var filters = _interopRequireWildcard(require("./filters"));

var _vue2Comps = _interopRequireDefault(require("@dmg/vue2-comps"));

var common = _interopRequireWildcard(require("@/utils/common"));

var _http = require("@/utils/http");

// A modern alternative to CSS resets
// global css
// internationalization
// icon
// permission control
// 指令
_vue.default.use(_index3.default);

// 全局组件
_vue.default.use(_global.default);

// 过滤器
Object.keys(filters).forEach(function (key) {
  _vue.default.filter(key, filters[key]);
});

_vue.default.use(_vue2Comps.default);

_vue.default.use(_elementUi.default, {
  size: 'small',
  // set element-ui default size
  i18n: function i18n(key, value) {
    return _lang.default.t(key, value);
  }
});

_vue.default.config.productionTip = false; // 注册全局对象

_vue.default.prototype.$common = common;
(0, _loadSysConf.default)().then(function () {
  (0, _http.setVm)(new _vue.default({
    el: '#app',
    router: _router.default,
    store: _store.default,
    i18n: _lang.default,
    render: function render(h) {
      return h(_App.default);
    }
  }));
});