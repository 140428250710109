"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/qGtedu_k/0/root/art_nft/dev-web/vue-admin/node_modules/@babel/runtime-corejs2/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.date = date;
exports.dict = dict;
exports.fen2Yuan = fen2Yuan;

var _common = require("@/utils/common");

var _store = _interopRequireDefault(require("@/store"));

/**
 * 全局过滤器
 */

/**
 * 日期格式化
 * @param {*} value
 * @param {string} format 默认 '{y}-{m}-{d} {h}:{i}:{s}'
 */
function date(value, format) {
  return (0, _common.parseTime)(value, format);
}
/**
 * 字典转换
 * {{ value | dict(table,filed) }}
 * @param {*} value
 * @param {*} table
 * @param {*} filed
 */


function dict(value, table, filed) {
  function objGet(obj, keys) {
    if (keys.length === 0 || !(obj instanceof Object)) {
      return obj;
    }

    return objGet(obj[keys.shift()], keys);
  }

  return objGet(_store.default.getters.dict || {}, [table, filed, value]);
}

function fen2Yuan(value) {
  return (value ? value / 100 : 0).toFixed(2);
} // 扩展
// export function xxx(value, xxxx) {
//   ....todo....
//   return xxxxxxxx
// }