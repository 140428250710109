"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = [{
  path: 'list',
  component: function component() {
    return import("./list");
  },
  meta: {
    title: '评论家列表'
  }
}, {
  path: 'details/:id',
  component: function component() {
    return import("./details");
  },
  meta: {
    title: '评论家详情'
  }
}, {
  path: 'form',
  component: function component() {
    return import("./form");
  },
  meta: {
    title: '新增评论家'
  }
}, {
  path: 'form/:id',
  component: function component() {
    return import("./form");
  },
  meta: {
    title: '编辑评论家'
  }
}];
exports.default = _default;