"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  computed: {
    langs: function langs() {
      var _this = this;

      if (!this.$i18n.messages) {
        return [];
      } else {
        return Object.keys(this.$i18n.messages).map(function (lang) {
          return {
            key: lang,
            value: _this.$i18n.messages[lang].__LANG_NAME || lang
          };
        }).reverse();
      }
    },
    language: function language() {
      return this.$store.getters.language;
    }
  },
  methods: {
    handleSetLanguage: function handleSetLanguage(lang) {
      this.$i18n.locale = lang;
      this.$store.dispatch('app/setLanguage', lang);
    }
  }
};
exports.default = _default;