"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/qGtedu_k/0/root/art_nft/dev-web/vue-admin/node_modules/@babel/runtime-corejs2/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _http = _interopRequireDefault(require("@/utils/http"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      loading: false,
      notices: [],
      unreadNum: 0
    };
  },
  mounted: function mounted() {
    this.loadData();
  },
  methods: {
    loadData: function loadData() {
      var _this = this;

      if (this.loading) return;
      this.loading = true;

      _http.default.request({
        url: 'notify/my_notify'
      }).then(function (res) {
        _this.unreadNum = 0;
        _this.notices = res.data.data.map(function (val) {
          if (!val.read) _this.unreadNum++;
          return {
            id: val.notify_id,
            read: val.read,
            title: val.title,
            time: val.created_at,
            img: 'https://gw.alipayobjects.com/zos/rmsportal/ThXAXghbEsBCCSDihZxY.png'
          };
        });
        _this.loading = false;
      });
    }
  }
};
exports.default = _default;