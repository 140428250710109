var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar }
      }),
      _vm._v(" "),
      _c("breadcrumb", { staticClass: "breadcrumb-container" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _c(
            "span",
            {
              staticStyle: {
                color: "red",
                "font-size": "18px",
                "font-weight": "700"
              }
            },
            [_vm._v(_vm._s(_vm.copyright_warning))]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "right-menu-item hover-effect",
              staticStyle: { "font-size": "14px" }
            },
            [
              _c("router-link", { attrs: { to: "/easy-export/list" } }, [
                _vm._v("\n        导出任务\n      ")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c("Notice", { staticClass: "right-menu-item hover-effect" }),
          _vm._v(" "),
          _c("lang-select", { staticClass: "right-menu-item hover-effect" }),
          _vm._v(" "),
          _c(
            "el-dropdown",
            { staticClass: "avatar-container", attrs: { trigger: "click" } },
            [
              _c("div", { staticClass: "avatar-wrapper" }, [
                _c("span", [_vm._v(_vm._s(_vm.name))]),
                _vm._v(" "),
                _c("i", { staticClass: "el-icon-caret-bottom" })
              ]),
              _vm._v(" "),
              _c(
                "el-dropdown-menu",
                {
                  staticClass: "user-dropdown",
                  attrs: { slot: "dropdown" },
                  slot: "dropdown"
                },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/person/settings" } },
                    [
                      _c(
                        "el-dropdown-item",
                        [
                          _c("svg-icon", {
                            staticStyle: { "margin-right": "5px" },
                            attrs: { "icon-class": "setting" }
                          }),
                          _vm._v("\n            个人中心\n          ")
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-dropdown-item",
                    {
                      attrs: { divided: "" },
                      nativeOn: {
                        click: function($event) {
                          return _vm.logout($event)
                        }
                      }
                    },
                    [
                      _c(
                        "span",
                        { staticStyle: { display: "block" } },
                        [
                          _c("svg-icon", {
                            staticStyle: { "margin-right": "5px" },
                            attrs: { "icon-class": "logout" }
                          }),
                          _vm._v("\n            退出登录\n          ")
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }