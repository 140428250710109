var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-popover",
    {
      ref: "noticePopover",
      attrs: {
        "popper-class": "notice-popover",
        width: "340",
        trigger: "click"
      },
      on: { show: _vm.loadData }
    },
    [
      _c(
        "el-badge",
        {
          staticClass: "notice-badge",
          attrs: { slot: "reference", value: _vm.unreadNum },
          slot: "reference"
        },
        [_c("i", { staticClass: "el-icon-message-solid" })]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "notice-container" },
        [
          _c("div", { staticClass: "notice-header" }, [_vm._v("通知")]),
          _vm._v(" "),
          _vm._l(_vm.notices, function(notice) {
            return _c(
              "router-link",
              { key: notice.notice_id, attrs: { to: "/notice/" + notice.id } },
              [
                _c("div", { staticClass: "notice-item" }, [
                  _c(
                    "div",
                    {
                      class: {
                        "notice-item-container": true,
                        read: notice.read
                      }
                    },
                    [
                      _c("div", [_c("img", { attrs: { src: notice.img } })]),
                      _vm._v(" "),
                      _c("div", [
                        _c("div", [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm._f("date")(notice.time)) +
                              "\n              "
                          ),
                          _c("br"),
                          _vm._v(
                            "\n              " +
                              _vm._s(notice.title) +
                              "\n            "
                          )
                        ])
                      ])
                    ]
                  )
                ])
              ]
            )
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "notice-footer" },
            [
              _c("router-link", { attrs: { to: "/notice/list" } }, [
                _vm._v("查看所有通知")
              ])
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }