"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = [{
  path: 'list',
  component: function component() {
    return import("./list");
  },
  meta: {
    title: '保底抽奖列表'
  }
}, {
  path: 'form',
  component: function component() {
    return import("./form");
  },
  meta: {
    title: '保底抽奖新增与编辑'
  }
}, {
  path: 'record_list',
  component: function component() {
    return import("./record_list");
  },
  meta: {
    title: '保底抽奖记录'
  }
}];
exports.default = _default;