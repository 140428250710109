"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = [{
  path: 'list',
  component: function component() {
    return import("./list");
  },
  meta: {
    title: '用户列表',
    keep: true
  }
}, {
  path: 'form',
  component: function component() {
    return import("./form");
  },
  meta: {
    title: '编辑用户详情'
  }
}, {
  path: 'detail',
  component: function component() {
    return import("./detail");
  },
  meta: {
    title: '用户详情'
  }
}, {
  path: 'wallets',
  component: function component() {
    return import("./wallets");
  },
  meta: {
    title: '账户管理'
  }
}, {
  path: 'adjustment-history',
  component: function component() {
    return import("./adjustment-history");
  },
  meta: {
    title: '调账记录'
  }
}, {
  path: 'phone-history',
  component: function component() {
    return import("./phone-history");
  },
  meta: {
    title: '换绑手机号记录'
  }
}, {
  path: 'flow',
  component: function component() {
    return import("./flow");
  },
  meta: {
    title: '用户流水',
    keep: true
  }
}, {
  path: 'refer-statistics',
  component: function component() {
    return import("./refer-statistics");
  },
  meta: {
    title: '邀新统计',
    keep: true
  }
}];
exports.default = _default;