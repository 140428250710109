"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = [{
  path: 'list',
  component: function component() {
    return import("./list");
  },
  meta: {
    title: '艺术家列表',
    keep: true
  }
}, {
  path: 'details/:id',
  component: function component() {
    return import("./details");
  },
  meta: {
    title: '艺术家详情'
  }
}, {
  path: 'form',
  component: function component() {
    return import("./form");
  },
  meta: {
    title: '新增艺术家'
  }
}, {
  path: 'form/:id',
  component: function component() {
    return import("./form");
  },
  meta: {
    title: '编辑艺术家'
  }
}, {
  path: 'settled_list',
  component: function component() {
    return import("./settled-list");
  },
  meta: {
    title: '艺术家入驻列表'
  }
}];
exports.default = _default;