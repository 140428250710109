"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/qGtedu_k/0/root/art_nft/dev-web/vue-admin/node_modules/@babel/runtime-corejs2/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getToken = getToken;
exports.setToken = setToken;
exports.removeToken = removeToken;
exports.getPublicKey = getPublicKey;
exports.setPublicKey = setPublicKey;

var _storage = _interopRequireDefault(require("@/utils/storage"));

var tokenKey = process.env.VUE_APP_TOKEN_KEY_PREFIX + '_TOKEN';
var publicKeyName = process.env.VUE_APP_TOKEN_KEY_PREFIX + '_PUBLIC_KEY';

function getToken() {
  return _storage.default.get(tokenKey);
}

function setToken(token) {
  return _storage.default.set(tokenKey, token);
}

function removeToken() {
  return _storage.default.remove(tokenKey);
}

function getPublicKey() {
  return _storage.default.get(publicKeyName);
}

function setPublicKey(publicKey) {
  return _storage.default.set(publicKeyName, publicKey);
}