"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  __LANG_NAME: '简体中文',
  common: {
    operate: "操作",
    update_status_success: "更新状态成功",
    btn: {
      back: "返回",
      submit: "提交",
      prev: "上一步",
      next: "下一步",
      update: "编辑",
      reply: "回复",
      show: "查看",
      add: "新增",
      delete: "删除",
      upload: "上传",
      download_temp: "下载导入模板",
      search: "搜索",
      import: "导入",
      export: "导出",
      data: "数据",
      select_goods: "选择商品",
      select_goods_description: "活动过期或者移除商品后商品的属性和价格恢复为原来的设置",
      store: "上架",
      unstore: "下架"
    },
    updated_at: "修改时间",
    created_at: "新增时间",
    no_info: "信息不存在",
    confirm_delete: "确认要删除吗？",
    delete_success: "删除成功",
    submit_success: "提交成功",
    confirm_review1: "确认要审核通过吗？",
    confirm_review2: "确认要审核失败吗？",
    selected_all: "全选",
    select_delete_error: "请选择要删除的数据",
    select_review_error: "请选择要审核的数据",
    set_success: "设置成功",
    save_success: "保存成功",
    url_tip: "请填写完整url(http开头)",
    cancel: "取消",
    apply_info: "是否通过审核",
    apply_success: "审核通过",
    store_name: "所属店铺",
    currency_symbol: "￥",
    get_code: "获取验证码",
    resend: "重新发送",
    SMS_authentication: "短信认证",
    Google_key_certification: "谷歌秘钥认证",
    "4-digit_SMS_verificationcode": "请输入4位短信验证码",
    "6-bit_Google_key": "请输入6位谷歌秘钥",
    send_sms_to: "短信将发送至",
    store_success: "上架成功",
    confirm_unstore_success: "是否要下架",
    unstore_success: "下架成功"
  },
  tagsView: {
    refresh: '刷新',
    close: '关闭',
    closeOthers: '关闭其它',
    closeAll: '关闭所有'
  },
  "menu": {
    "account": {
      "settings": "个人设置",
      "logout": "退出登录"
    },
    "fullscreen": "全屏",
    "fullscreen_exit": "退出全屏",
    "clear_local_storage": "清理本地缓存",
    "lang": "语言"
  },
  "import": {
    "result_total": "总共导入",
    "result_success": "成功",
    "result_fail": "失败",
    "result_end": "条数据。"
  },
  "upload_lib": {
    "defaultGroup": "默认分组",
    "image": {
      "title": "我的图片"
    },
    "btn": {
      "ok": "使用文件",
      "move": "移动文件",
      "addGroup": "新增分组",
      "updateGroup": "修改分组",
      "deleteGroup": "删除分组",
      "editFile": "修改文件",
      "deleteFile": "删除文件"
    },
    "group": {
      "name": "分组名称"
    },
    "file": {
      "name": "文件名称"
    },
    "error": {
      "select_files": "请选择文件",
      "select_one": "请选择一个文件"
    },
    "success": {
      "move": "文件移动成功"
    }
  },
  "app": {
    "login": {
      "message-invalid-credentials": "账户或密码错误（admin/ant.design）",
      "message-invalid-verification-code": "验证码错误",
      "tab-login-credentials": "账户密码登录",
      "tab-login-mobile": "手机号登录",
      "remember-me": "自动登录",
      "forgot-password": "忘记密码",
      "sign-in-with": "其他登录方式",
      "signup": "注册账户",
      "login": "登录",
      "username": "请输入帐号(4-30个字母)",
      "username-invalid-required": "请输入帐号",
      "password": "请输入密码",
      "password-invalid-required": "请输入不少于6位的密码",
      "tel": "请输入手机号码",
      "tel-invalid-required": "请输入正确手机号码",
      "code": "请输入验证码",
      "code-invalid-required": "请输入4位验证码",
      "registerBtn": "注册完成,立即使用",
      "resetBtn": "重置密码",
      "account": "输入账号(字母、数字及下划线、4-30个字母)",
      "account-invalid-required": "输入账号(字母、数字及下划线、4-30个字母)"
    }
  },
  admin: {
    name: "用户名",
    password: "密码",
    head_pic: "头像",
    username: "用户名",
    confirm_password: "确认密码",
    password_common: "新旧密码不能相同",
    old_password_null: "旧密码不能为空",
    confirm_password_null: "确认密码不能为空",
    confirm_password_password_common: "新密码和确认密码不一样",
    old_password: "旧密码",
    phone: "手机号",
    old_phone: "旧手机号",
    role: "所属角色",
    created_at: "创建时间",
    heading_title_update: "编辑管理员",
    heading_title_add: "新增管理员",
    pre_set: "预留信息",
    old_pre_set: "旧预留信息",
    admin_title: "管理员列表"
  },
  user: {
    status: "用户状态",
    heading_title_add: "新增用户",
    heading_title_update: "编辑用户",
    userinfo: "用户详情",
    user_id: "用户账号",
    role: "所属角色",
    password: "密码",
    username: "用户账号",
    phone: "手机号",
    nickname: "用户昵称",
    rank: "等级",
    created_at: "注册时间",
    address: "收货地址",
    mobile: "手机号",
    update_user_level: "修改用户等级",
    idcard: "身份证号",
    login_times: "登录次数"
  },
  role: {
    name: "角色名称",
    info: "角色描述",
    auth: "权限分配",
    created_at: "创建时间",
    heading_title_update: "编辑角色",
    heading_title_add: "新增角色",
    role_title: "角色列表"
  }
};
exports.default = _default;