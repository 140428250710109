"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = [{
  path: 'list',
  component: function component() {
    return import("./list");
  },
  meta: {
    title: '日历列表'
  }
}, {
  path: 'form',
  component: function component() {
    return import("./form");
  },
  meta: {
    title: '新增日历'
  }
}, {
  path: 'form/:id',
  component: function component() {
    return import("./form");
  },
  meta: {
    title: '编辑日历'
  }
}];
exports.default = _default;