"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/qGtedu_k/0/root/art_nft/dev-web/vue-admin/node_modules/@babel/runtime-corejs2/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getLanguage = getLanguage;
exports.default = void 0;

require("core-js/modules/es6.object.keys");

var _objectSpread2 = _interopRequireDefault(require("/home/gitlab-runner/builds/qGtedu_k/0/root/art_nft/dev-web/vue-admin/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2"));

require("core-js/modules/web.dom.iterable");

var _vue = _interopRequireDefault(require("vue"));

var _vueI18n = _interopRequireDefault(require("vue-i18n"));

var _storage = _interopRequireDefault(require("@/utils/storage"));

var localeLangPaths = require.context('.', false, /(.*)\.js$/).keys().filter(function (i) {
  return i !== 'index.js';
});

_vue.default.use(_vueI18n.default);

var messages = {};
localeLangPaths.forEach(function (localePath) {
  var name = localePath.slice(2, -3);
  if (name === 'index') return;

  var locale = require("./".concat(name)).default;

  var element = require("element-ui/lib/locale/lang/".concat(name)).default;

  messages[name] = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, locale), element);
});

function getLanguage() {
  var chooseLanguage = _storage.default.get('language', '');

  if (chooseLanguage && messages[chooseLanguage]) return chooseLanguage; // if has not choose language

  var language = (navigator.language || navigator.browserLanguage).toLowerCase();
  var locales = Object.keys(messages);

  for (var _i = 0, _locales = locales; _i < _locales.length; _i++) {
    var locale = _locales[_i];

    if (language.indexOf(locale) > -1) {
      return locale;
    }
  }

  return 'zh-CN';
}

var i18n = new _vueI18n.default({
  // set locale
  // options: en | zh | es
  locale: getLanguage(),
  // set locale messages
  messages: messages
});
var _default = i18n;
exports.default = _default;