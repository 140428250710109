"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    logo: function logo() {
      var full = this.$sysconf.menu_logo_full || require('@/assets/logo-full.png');

      var logo = this.$sysconf.menu_logo || require('@/assets/logo.png');

      return this.collapse ? logo : full;
    }
  }
};
exports.default = _default;