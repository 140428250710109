"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/qGtedu_k/0/root/art_nft/dev-web/vue-admin/node_modules/@babel/runtime-corejs2/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _acl = _interopRequireDefault(require("./acl"));

// 在此注册
var directives = {
  acl: _acl.default
};
var _default = {
  install: function install(Vue) {
    for (var k in directives) {
      Vue.directive(k, directives[k]);
    }
  }
};
exports.default = _default;