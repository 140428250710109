var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.item.children || _vm.item.children.length == 0
        ? [
            _c(
              "app-link",
              { attrs: { to: "/" + _vm.item.link } },
              [
                _c(
                  "el-menu-item",
                  {
                    class: { "submenu-title-noDropdown": !_vm.isNest },
                    attrs: { index: "/" + _vm.item.link }
                  },
                  [
                    _c("item", {
                      attrs: { icon: _vm.item.icon, title: _vm.item.text }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]
        : _c(
            "el-submenu",
            {
              ref: "subMenu",
              attrs: {
                index: String(_vm.item.item_id),
                "popper-append-to-body": ""
              }
            },
            [
              _c(
                "template",
                { slot: "title" },
                [
                  _c("item", {
                    attrs: { icon: _vm.item.icon, title: _vm.item.text }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _vm._l(_vm.item.children, function(child) {
                return _c("sidebar-item", {
                  key: child.item_id,
                  staticClass: "nest-menu",
                  attrs: { "is-nest": true, item: child }
                })
              })
            ],
            2
          )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }