var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    [
      _c(
        "el-col",
        { attrs: { span: 10 } },
        [
          _c("el-input", {
            attrs: { placeholder: "验证码" },
            model: {
              value: _vm.code,
              callback: function($$v) {
                _vm.code = $$v
              },
              expression: "code"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-col",
        {
          staticStyle: { "text-align": "right" },
          attrs: { span: 12, offset: 2 }
        },
        [
          _c(
            "el-button",
            {
              staticStyle: { width: "134px" },
              attrs: { type: "primary", loading: _vm.smsLoading },
              on: { click: _vm.sendSMS }
            },
            [_vm._v(_vm._s(_vm.smsButtonName))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }