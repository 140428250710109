"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = [{
  path: 'list',
  component: function component() {
    return import("./list");
  },
  meta: {
    title: '支付记录列表'
  }
}, {
  path: 'refund_list',
  component: function component() {
    return import("./refund-list");
  },
  meta: {
    title: '退款记录列表'
  }
}];
exports.default = _default;