"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var VUE_APP_BASE_API = process.env.VUE_APP_BASE_API;

if (!VUE_APP_BASE_API) {
  VUE_APP_BASE_API = location.origin + process.env.VUE_APP_BASE_API_SUFFIX;
}

var _default = {
  VUE_APP_BASE_API: VUE_APP_BASE_API
};
exports.default = _default;