"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isExternal = isExternal;
exports.validUsername = validUsername;
exports.validPassword = validPassword;
exports.validMobile = validMobile;
exports.validCode = validCode;
exports.validIdCard = validIdCard;

/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * @param {string} path
 * @returns {Boolean}
 */
function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}
/**
 * @param {string} str
 * @returns {Boolean}
 */


function validUsername(str) {
  return /^[A-Za-z0-9]{4,16}$/.test(str);
}
/**
 * 请输入6-16位，至少包括一个字母、一个数字，可使用特殊字符
 * @param {string} str
 * @returns {Boolean}
 */


function validPassword(str) {
  return /(?=.*([a-zA-Z].*))(?=.*[0-9].*)[a-zA-Z0-9-*/+.~!@#$%^&*()]{6,16}$/.test(str);
}
/**
 * @param {string} str
 * @returns {Boolean}
 */


function validMobile(str) {
  return /^1[3-9]{1}[0-9]{9}$/.test(str);
}
/**
 * @param {string} str
 * @returns {Boolean}
 */


function validCode(str) {
  return /^[A-Za-z0-9]{4}$/.test(str);
}
/**
 * @param {string} str
 * @returns {Boolean}
 */


function validIdCard(str) {
  return /^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/.test(str);
}