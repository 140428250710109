"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/qGtedu_k/0/root/art_nft/dev-web/vue-admin/node_modules/@babel/runtime-corejs2/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.passwordEncode = passwordEncode;
exports.moduleRoutes = moduleRoutes;
exports.isPromise = isPromise;
exports.parseTime = parseTime;
exports.param2Obj = param2Obj;
exports.objGet = objGet;
exports.objSet = objSet;
exports.objFlat = objFlat;
exports.objPlump = objPlump;
exports.objKeySort = objKeySort;
exports.isEmptyObj = isEmptyObj;
exports.deepLoopThrough = deepLoopThrough;
exports.downloadFile = downloadFile;
exports.deepCopy = deepCopy;
exports.clicpboard = clicpboard;
exports.isEmpty = isEmpty;
exports.uuid = uuid;
exports.getQueryString = getQueryString;
exports.getDictMap = getDictMap;
exports.acl = acl;
exports.formatMoney = formatMoney;
exports.toPercent = toPercent;
exports.params = params;
exports.getDictKeyValue = getDictKeyValue;
exports.arrayColumn = arrayColumn;
exports.resolveApiUrl = resolveApiUrl;
exports.object2FormData = object2FormData;

require("core-js/modules/es6.number.constructor");

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

require("core-js/modules/es6.array.sort");

var _objectSpread2 = _interopRequireDefault(require("/home/gitlab-runner/builds/qGtedu_k/0/root/art_nft/dev-web/vue-admin/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2"));

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/es6.regexp.split");

var _typeof2 = _interopRequireDefault(require("/home/gitlab-runner/builds/qGtedu_k/0/root/art_nft/dev-web/vue-admin/node_modules/@babel/runtime-corejs2/helpers/esm/typeof"));

require("core-js/modules/es6.regexp.to-string");

var _extend = _interopRequireDefault(require("extend"));

var _rsa = _interopRequireDefault(require("@/utils/rsa"));

var _store = _interopRequireDefault(require("@/store"));

var _httpBuildQuery = _interopRequireDefault(require("http-build-query"));

var _auth = require("./auth");

var _env = _interopRequireDefault(require("./env"));

/**
 * 密码加密
 * @param {*} password
 * @param {*} publicKey
 */
function passwordEncode(password, publicKey) {
  if (!publicKey && !(publicKey = (0, _auth.getPublicKey)())) {
    throw Error('public key is null');
  }

  var bl = parseInt((publicKey.length / 2).toString(), 10) + 1;

  _rsa.default.RSAKeyPair('10001', '10001', publicKey, 512, bl); // 10001为上述公开指数e的十六进制数


  return window.btoa(_rsa.default.encryptedString(_rsa.default, password, _rsa.default.PKCS1Padding, _rsa.default.RawEncoding, bl));
}
/**
 * 路由文件载入
 * @param {*} prefix
 * @param {*} module
 */


function moduleRoutes(prefix, module) {
  return module.default.map(function (i) {
    i.path = "".concat(prefix, "/").concat(i.path);
    return i;
  });
}
/**
 * 判断是否Promise
 * @param {*} obj
 */


function isPromise(obj) {
  return !!obj && ((0, _typeof2.default)(obj) === 'object' || typeof obj === 'function') && typeof obj.then === 'function';
}
/**
 * 根据格式字符串格式时间
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */


function parseTime(time, cFormat) {
  var dayjs = require('dayjs');

  if (arguments.length === 0 || !time) {
    return null;
  }

  var format = cFormat || 'YYYY-MM-DD HH:mm:ss';

  if ((0, _typeof2.default)(time) === 'object') {
    return dayjs(time).format(format);
  } else {
    time = String(time);

    if (/^[0-9]+$/.test(time)) {
      if (time.length === 10) {
        return dayjs.unix(time).format(format);
      } else {
        return dayjs(parseInt(time)).format(format);
      }
    }
  }

  return null;
}
/**
 * 参数F字符串转对象
 * @param {string} url
 * @returns {Object}
 */


function param2Obj(url) {
  var search = url.split('?')[1];

  if (!search) {
    return {};
  }

  return JSON.parse('{"' + decodeURIComponent(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"').replace(/\+/g, ' ') + '"}');
}
/**
 * 对于
 * {
 *      body:{
 *          data:{
 *              data:[1,2,3],
 *              pagenation:{page:1,pageSize:10},
 *          }
 *      }
 *  }
 * objGet(json,'body.data.data');  //res === [1,2,3]
 * objGet(json,'body.request.data');//value === undefined
 * objGet(json,'body.data.data',true); //resType==='Array'
 * objGet(json,'body.data.data','Array');//isType===true
 *
 * @param {Object} data
 * @param {string|Array} fields
 * @param {string} pattern
 */


function objGet(data, fields, pattern) {
  var arr = fields;

  if (!fields) {
    return undefined;
  } else if (typeof fields === 'string') {
    arr = fields.split('.');
  }

  var value = data[arr.shift()];

  if (value === null || value === undefined) {
    return value;
  } else if (arr.length === 0) {
    if (!pattern) return value;
    var type = Object.prototype.toString.call(value).replace('[object ', '').replace(']', '');

    if (pattern === true) {
      return type;
    } else if (!pattern) {
      return value;
    } else {
      return type === pattern;
    }
  }

  return objGet(value, arr, pattern);
}
/**
 *
 * @param {*} data
 * @param {*} fields
 * @param {*} value
 * @param {*} seg '.'
 */


function objSet(data, fields, value, seg) {
  var arr = fields;

  if (!fields) {
    return value;
  } else if (typeof fields === 'string') {
    arr = fields.split(seg || '.');
  }

  if (!arr || !arr.length) {
    return value;
  }

  var key = arr.shift();
  data = data || {};
  data[key] = objSet(data[key], arr, value, seg);
  return data;
}
/**
 * 压扁一个对象
 * @param {*} data
 * @param {*} seg
 * @param {*} keyPrefix
 */


function objFlat(data, seg, keyPrefix) {
  seg = seg || '.';
  keyPrefix = keyPrefix || '';
  var obj = {};

  for (var k in data) {
    if ((0, _typeof2.default)(data[k]) === 'object' && !Array.isArray(data[k])) {
      obj = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, obj), objFlat(data[k], seg, keyPrefix + k + seg));
    } else {
      obj[keyPrefix + k] = data[k];
    }
  }

  return obj;
}
/**
 * 丰满一个对象，objFlat的逆向
 * @param {*} data
 * @param {*} seg
 */


function objPlump(data, seg) {
  var obj = {};

  for (var key in data) {
    obj = objSet(obj, key, data[key], seg);
  }

  return obj;
}
/**
 * 对象按照key排序
 * @param obj
 * @param compareFn 排序方法 可选
 */


function objKeySort(obj, compareFn) {
  // 排序的函数
  var newkey = Object.keys(obj).sort(compareFn); // 先用Object内置类的keys方法获取要排序对象的属性名，再利用Array原型上的sort方法对获取的属性名进行排序，newkey是一个数组

  var newObj = {}; // 创建一个新的对象，用于存放排好序的键值对

  for (var i in newkey) {
    // 遍历newkey数组
    // 向新创建的对象中按照排好的顺序依次增加键值对
    newObj[newkey[i]] = obj[newkey[i]];
  }

  return newObj; // 返回排好序的新对象
}
/**
 * 判断是否空对象
 */


function isEmptyObj(obj) {
  for (var key in obj) {
    return false; // 返回false，不为空对象
  }

  return true; // 返回true，为空对象
}
/**
 * 深度遍历对象,回调方法的返回值值覆盖原有值
 *
 * @param {Object} obj
 * @param {Function} fun
 */


function deepLoopThrough(obj, fun) {
  for (var key in obj) {
    var tmpReturn = fun(key, obj[key]);

    if (tmpReturn) {
      obj[key] = tmpReturn;
    }

    if (obj[key] instanceof Object) {
      deepLoopThrough(obj[key], fun);
    }
  }
}
/**
 * 文件下载
 * @param {String} filePath 文件地址/路径
 * @param {String} fileName 文件名，不传使用则默认
 */


function downloadFile(filePath, fileName) {
  var a = document.createElement("a");
  document.body.appendChild(a);
  a.target = '_blank';
  a.href = filePath;

  if (fileName) {
    a.download = fileName;
  }

  a.click();
  a.parentNode.removeChild(a);
}
/**
 * 深拷贝
 * @param {*} obj
 */


function deepCopy(obj) {
  var result = (0, _extend.default)(true, {}, {
    _: obj
  });
  return result._;
}
/**
 * 复制到剪切板
 * @param {*} value
 */


function clicpboard(value, showMsg) {
  return new Promise(function (resolve) {
    var copyTextArea = null;

    try {
      copyTextArea = document.createElement('textarea');
      copyTextArea.style.height = '0px';
      copyTextArea.style.opacity = '0';
      copyTextArea.style.width = '0px';
      document.body.appendChild(copyTextArea);
      copyTextArea.value = value;
      copyTextArea.select();
      document.execCommand('copy');

      if (showMsg) {
        var _require = require('element-ui'),
            Message = _require.Message;

        Message.success('复制成功！');
      }

      resolve(value);
    } finally {
      if (copyTextArea && copyTextArea.parentNode) {
        copyTextArea.parentNode.removeChild(copyTextArea);
      }
    }
  });
}
/**
 * 空对象判断
 * @param {*} data
 */


function isEmpty(data) {
  if (data === null || typeof data === 'undefined') {
    return true;
  }

  if ((0, _typeof2.default)(data) === 'object') {
    return isEmptyObj(data);
  } else if (typeof data === 'string') {
    data = data.trim();
    return data === '';
  }

  return data;
}
/**
 * 生成UUID
 */


function uuid() {
  function S4() {
    return ((1 + Math.random()) * 0x10000 | 0).toString(16).substring(1);
  }

  return S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4();
}
/**
 * 提取url参数
 * @param {*} href
 * @param {*} name
 */


function getQueryString(href, name) {
  var query = href.substring(href.indexOf('?') + 1);
  var vars = query.split("&");

  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");

    if (pair[0] === name) {
      return pair[1];
    }
  }

  return '';
}
/**
 * 获取字典映射数组
 *
 * @param table  指定表名
 * @param field  指定字段名
 * @returns {Array} 可用于 enum格式  [ { label: "名称", value: 值 } ...]
 */


function getDictMap(table, field) {
  var dict = _store.default.state.dict.dict[_store.default.getters.language];

  if (dict[table] instanceof Object && dict[table][field] instanceof Object) {
    dict = dict[table][field];
    return Object.keys(dict).map(function (key) {
      return {
        label: dict[key],
        value: key
      };
    });
  } else {
    return [];
  }
}
/**
 * 权限判断
 * @param {*} value
 */


function acl(value) {
  return !value.some(function (acl) {
    return !_store.default.getters.permission.includes(acl);
  });
}
/*
 * 格式化金额
 * @param data
 * @return {string}
 */


function formatMoney(data) {
  var digits = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;

  if (data === null || typeof data == 'undefined') {
    return '';
  }

  if (typeof data !== 'number') {
    data = Number(data);
  }

  data = (data / 100).toFixed(digits);
  return data;
}
/**
 * 格式化百分比
 * @param num
 * @param total
 * @return {string}
 */


function toPercent(num) {
  var total = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 10000;

  if (typeof num !== 'number') {
    num = Number(num);
  }

  if (typeof total !== 'number') {
    total = Number(total);
  }

  return Math.round(num / total * 10000) / 100.00 + "%"; // 小数点后两位百分比
}
/**
 * 拼接url参数
 * @param data
 * @return {string}
 */


function params(data) {
  var url = '';

  for (var k in data) {
    var value = data[k] ? data[k] : '';
    url += "&".concat(k, "=").concat(encodeURIComponent(value));
  }

  return url;
}
/**
 * 获取字典映射值
 *
 * @param table  指定表名
 * @param field  指定字段名
 * @param key
 * @returns {string}
 */


function getDictKeyValue(table, field, key, dictData) {
  return objGet(dictData || _store.default.state.dict.dict, [_store.default.getters.language, table, field, key]);
}
/**
 * 类似PHP的array_column
 */


function arrayColumn(arr, key) {
  return arr.map(function (item) {
    return item[key];
  });
}
/**
 * 构建带token与的完整url
 */


function resolveApiUrl(url, params) {
  params = params || {};
  params.token = _store.default.getters.token;
  return _env.default.VUE_APP_BASE_API + url + '?' + (0, _httpBuildQuery.default)(params);
}
/**
 * 对象转FormData表单对象
 *
 * @param data 需要转换的源数据，object类型
 * @param keys 父级数据键
 * @param form 表单对象
 * @returns {FormData}
 */


function object2FormData(data) {
  var keys = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "";
  var form = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  form = form || new FormData();
  Object.keys(data).forEach(function (key) {
    var item = data[key];
    var ks = keys ? "".concat(keys, "[").concat(key, "]") : key;

    if ((0, _typeof2.default)(item) === "object") {
      if (item instanceof File) {
        form.append(ks, item);
      } else if (item instanceof Array || item instanceof Object) {
        object2FormData(item, ks, form);
      } else {
        form.append(ks, item);
      }
    } else {
      form.append(ks, item);
    }
  });
  return form;
}