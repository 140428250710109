"use strict";

require("core-js/modules/es6.number.constructor");

exports.__esModule = true;
var biRadixBase = 2;
var biRadixBits = 16;
var bitsPerDigit = biRadixBits;
var biRadix = 1 << 16; // = 2^16 = 65536

var biHalfRadix = biRadix >>> 1;
var biRadixSquared = biRadix * biRadix;
var maxDigitVal = biRadix - 1;
var maxInteger = 9999999999999998;
var hexToChar = new Array('0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f');
var hexatrigesimalToChar = new Array('0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z');
var highBitMasks = new Array(0x0000, 0x8000, 0xC000, 0xE000, 0xF000, 0xF800, 0xFC00, 0xFE00, 0xFF00, 0xFF80, 0xFFC0, 0xFFE0, 0xFFF0, 0xFFF8, 0xFFFC, 0xFFFE, 0xFFFF);
var lowBitMasks = new Array(0x0000, 0x0001, 0x0003, 0x0007, 0x000F, 0x001F, 0x003F, 0x007F, 0x00FF, 0x01FF, 0x03FF, 0x07FF, 0x0FFF, 0x1FFF, 0x3FFF, 0x7FFF, 0xFFFF);

var BigInt =
/** @class */
function () {
  function BigInt(value) {
    if (value === void 0) {
      value = null;
    }

    if (typeof value == "boolean" && value == true) {
      this.digits = null;
    } else if (typeof value == "number" || typeof value == "string") {
      var buff = new Array(value);

      for (var i = 0; i < buff.length; i++) {
        buff[i] = 0;
      }

      this.digits = buff.slice(0);
    } else {
      var buff = new Array(20);

      for (var i = 0; i < buff.length; i++) {
        buff[i] = 0;
      }

      this.digits = buff.slice(0);
    }

    this.isNeg = false;
  }

  BigInt.biHighIndex = function (x) {
    var result = x.digits.length - 1;

    while (result > 0 && x.digits[result] == 0) {
      --result;
    }

    return result;
  };

  BigInt.reverseStr = function (s) {
    var result = "";

    for (var i = s.length - 1; i > -1; --i) {
      result += s.charAt(i);
    }

    return result;
  };

  BigInt.digitToHex = function (n) {
    var mask = 0xf;
    var result = "";

    for (var i = 0; i < 4; ++i) {
      result += hexToChar[n & mask];
      n >>>= 4;
    }

    return this.reverseStr(result);
  };

  BigInt.biToHex = function (x) {
    var result = "";
    var n = this.biHighIndex(x);

    for (var i = this.biHighIndex(x); i > -1; --i) {
      result += this.digitToHex(x.digits[i]);
    }

    return result;
  };

  BigInt.biToBytes = function (x) {
    var result = "";

    for (var i = this.biHighIndex(x); i > -1; --i) {
      result += this.digitToBytes(x.digits[i]);
    }

    return result;
  };

  BigInt.digitToBytes = function (n) {
    var c1 = String.fromCharCode(n & 0xff);
    n >>>= 8;
    var c2 = String.fromCharCode(n & 0xff);
    return c2 + c1;
  };

  BigInt.biFromHex = function (s, bl) {
    var result = new BigInt(bl);
    var sl = s.length;

    for (var i = sl, j = 0; i > 0; i -= 4, ++j) {
      result.digits[j] = this.hexToDigit(s.substr(Math.max(i - 4, 0), Math.min(i, 4)));
    }

    return result;
  };

  BigInt.hexToDigit = function (s) {
    var result = 0;
    var sl = Math.min(s.length, 4);

    for (var i = 0; i < sl; ++i) {
      result <<= 4;
      result |= this.charToHex(s.charCodeAt(i));
    }

    return result;
  };

  BigInt.charToHex = function (c) {
    var ZERO = 48;
    var NINE = ZERO + 9;
    var littleA = 97;
    var littleZ = littleA + 25;
    var bigA = 65;
    var bigZ = 65 + 25;
    var result;

    if (c >= ZERO && c <= NINE) {
      result = c - ZERO;
    } else if (c >= bigA && c <= bigZ) {
      result = 10 + c - bigA;
    } else if (c >= littleA && c <= littleZ) {
      result = 10 + c - littleA;
    } else {
      result = 0;
    }

    return result;
  };

  BigInt.biCompare = function (x, y) {
    if (x.isNeg != y.isNeg) {
      return 1 - 2 * Number(x.isNeg);
    }

    for (var i = x.digits.length - 1; i >= 0; --i) {
      if (x.digits[i] != y.digits[i]) {
        if (x.isNeg) {
          return 1 - 2 * Number(x.digits[i] > y.digits[i]);
        } else {
          return 1 - 2 * Number(x.digits[i] < y.digits[i]);
        }
      }
    }

    return 0;
  };

  BigInt.biNumBits = function (x) {
    var n = this.biHighIndex(x);
    var d = x.digits[n];
    var m = (n + 1) * bitsPerDigit;
    var result;

    for (result = m; result > m - bitsPerDigit; --result) {
      if ((d & 0x8000) != 0) break;
      d <<= 1;
    }

    return result;
  }; // 2 <= radix <= 36


  BigInt.biToString = function (x, radix, bl) {
    var b = new BigInt(bl);
    b.digits[0] = radix;
    var qr = this.biDivideModulo(x, b, bl);
    var result = hexatrigesimalToChar[qr[1].digits[0]];

    while (this.biCompare(qr[0], new BigInt(bl)) == 1) {
      qr = this.biDivideModulo(qr[0], b, bl);
      var digit = qr[1].digits[0];
      result += hexatrigesimalToChar[qr[1].digits[0]];
    }

    return (x.isNeg ? "-" : "") + this.reverseStr(result);
  };

  BigInt.biCopy = function (bi) {
    var result = new BigInt(true);
    result.digits = bi.digits.slice(0);
    result.isNeg = bi.isNeg;
    return result;
  };

  BigInt.biAdd = function (x, y, bl) {
    var result;

    if (x.isNeg != y.isNeg) {
      y.isNeg = !y.isNeg;
      result = this.biSubtract(x, y, bl);
      y.isNeg = !y.isNeg;
    } else {
      result = new BigInt(bl);
      var c = 0;
      var n = void 0;

      for (var i = 0; i < x.digits.length; ++i) {
        n = x.digits[i] + y.digits[i] + c;
        result.digits[i] = n & 0xffff;
        c = Number(n >= biRadix);
      }

      result.isNeg = x.isNeg;
    }

    return result;
  };

  BigInt.biSubtract = function (x, y, bl) {
    var result;

    if (x.isNeg != y.isNeg) {
      y.isNeg = !y.isNeg;
      result = this.biAdd(x, y, bl);
      y.isNeg = !y.isNeg;
    } else {
      result = new BigInt(bl);
      var n = void 0,
          c = void 0;
      c = 0;

      for (var i = 0; i < x.digits.length; ++i) {
        n = x.digits[i] - y.digits[i] + c;
        result.digits[i] = n & 0xffff; // Stupid non-conforming modulus operation.

        if (result.digits[i] < 0) result.digits[i] += biRadix;
        c = 0 - Number(n < 0);
      } // Fix up the negative sign, if any.


      if (c == -1) {
        c = 0;

        for (var i = 0; i < x.digits.length; ++i) {
          n = 0 - result.digits[i] + c;
          result.digits[i] = n & 0xffff; // Stupid non-conforming modulus operation.

          if (result.digits[i] < 0) result.digits[i] += biRadix;
          c = 0 - Number(n < 0);
        } // Result is opposite sign of arguments.


        result.isNeg = !x.isNeg;
      } else {
        // Result is same sign.
        result.isNeg = x.isNeg;
      }
    }

    return result;
  };

  BigInt.arrayCopy = function (src, srcStart, dest, destStart, n) {
    var m = Math.min(srcStart + n, src.length);

    for (var i = srcStart, j = destStart; i < m; ++i, ++j) {
      dest[j] = src[i];
    }
  };

  BigInt.biShiftLeft = function (x, n, bl) {
    var digitCount = Math.floor(n / bitsPerDigit);
    var result = new BigInt(bl);
    this.arrayCopy(x.digits, 0, result.digits, digitCount, result.digits.length - digitCount);
    var bits = n % bitsPerDigit;
    var rightBits = bitsPerDigit - bits;
    var i = 0,
        i1 = 0;

    for (i = result.digits.length - 1, i1 = i - 1; i > 0; --i, --i1) {
      result.digits[i] = result.digits[i] << bits & maxDigitVal | (result.digits[i1] & highBitMasks[bits]) >>> rightBits;
    }

    result.digits[0] = result.digits[i] << bits & maxDigitVal;
    result.isNeg = x.isNeg;
    return result;
  };

  BigInt.biMultiplyByRadixPower = function (x, n, bl) {
    var result = new BigInt(bl);
    this.arrayCopy(x.digits, 0, result.digits, n, result.digits.length - n);
    return result;
  };

  BigInt.biMultiplyDigit = function (x, y, bl) {
    var n, c, uv;
    var result = new BigInt(bl);
    n = this.biHighIndex(x);
    c = 0;

    for (var j = 0; j <= n; ++j) {
      uv = result.digits[j] + x.digits[j] * y + c;
      result.digits[j] = uv & maxDigitVal;
      c = uv >>> biRadixBits;
    }

    result.digits[1 + n] = c;
    return result;
  };

  BigInt.biShiftRight = function (x, n, bl) {
    var digitCount = Math.floor(n / bitsPerDigit);
    var result = new BigInt(bl);
    this.arrayCopy(x.digits, digitCount, result.digits, 0, x.digits.length - digitCount);
    var bits = n % bitsPerDigit;
    var leftBits = bitsPerDigit - bits;

    for (var i = 0, i1 = i + 1; i < result.digits.length - 1; ++i, ++i1) {
      result.digits[i] = result.digits[i] >>> bits | (result.digits[i1] & lowBitMasks[bits]) << leftBits;
    }

    result.digits[result.digits.length - 1] >>>= bits;
    result.isNeg = x.isNeg;
    return result;
  };

  BigInt.biDivideModulo = function (x, y, bl) {
    var bigOne = new BigInt(bl);
    bigOne.digits[0] = 1;
    var nb = this.biNumBits(x);
    var tb = this.biNumBits(y);
    var origYIsNeg = y.isNeg;
    var q, r;

    if (nb < tb) {
      // |x| < |y|
      if (x.isNeg) {
        q = this.biCopy(bigOne);
        q.isNeg = !y.isNeg;
        x.isNeg = false;
        y.isNeg = false;
        r = this.biSubtract(y, x, bl); // Restore signs, 'cause they're references.

        x.isNeg = true;
        y.isNeg = origYIsNeg;
      } else {
        q = new BigInt(bl);
        r = this.biCopy(x);
      }

      return new Array(q, r);
    }

    q = new BigInt(bl);
    r = x; // Normalize Y.

    var t = Math.ceil(tb / bitsPerDigit) - 1;
    var lambda = 0;

    while (y.digits[t] < biHalfRadix) {
      y = this.biShiftLeft(y, 1, bl);
      ++lambda;
      ++tb;
      t = Math.ceil(tb / bitsPerDigit) - 1;
    } // Shift r over to keep the quotient constant. We'll shift the
    // remainder back at the end.


    r = this.biShiftLeft(r, lambda, bl);
    nb += lambda; // Update the bit count for x.

    var n = Math.ceil(nb / bitsPerDigit) - 1;
    var b = this.biMultiplyByRadixPower(y, n - t, bl);

    while (this.biCompare(r, b) != -1) {
      // time_count++;
      // if (time_count >= time) {
      //     break;
      // }
      ++q.digits[n - t];
      r = this.biSubtract(r, b, bl);
    }

    for (var i = n; i > t; --i) {
      var ri = i >= r.digits.length ? 0 : r.digits[i];
      var ri1 = i - 1 >= r.digits.length ? 0 : r.digits[i - 1];
      var ri2 = i - 2 >= r.digits.length ? 0 : r.digits[i - 2];
      var yt = t >= y.digits.length ? 0 : y.digits[t];
      var yt1 = t - 1 >= y.digits.length ? 0 : y.digits[t - 1];

      if (ri == yt) {
        q.digits[i - t - 1] = maxDigitVal;
      } else {
        q.digits[i - t - 1] = Math.floor((ri * biRadix + ri1) / yt);
      }

      var c1 = q.digits[i - t - 1] * (yt * biRadix + yt1);
      var c2 = ri * biRadixSquared + (ri1 * biRadix + ri2);

      while (c1 > c2) {
        --q.digits[i - t - 1];
        c1 = q.digits[i - t - 1] * (yt * biRadix | yt1);
        c2 = ri * biRadix * biRadix + (ri1 * biRadix + ri2);
      }

      b = this.biMultiplyByRadixPower(y, i - t - 1, bl);
      r = this.biSubtract(r, this.biMultiplyDigit(b, q.digits[i - t - 1], bl), bl);

      if (r.isNeg) {
        r = this.biAdd(r, b, bl);
        --q.digits[i - t - 1];
      }
    }

    r = this.biShiftRight(r, lambda, bl); // Fiddle with the signs and stuff to make sure that 0 <= r < y.

    q.isNeg = x.isNeg != origYIsNeg;

    if (x.isNeg) {
      if (origYIsNeg) {
        q = this.biAdd(q, bigOne, bl);
      } else {
        q = this.biSubtract(q, bigOne, bl);
      }

      y = this.biShiftRight(y, lambda, bl);
      r = this.biSubtract(y, r, bl);
    } // Check for the unbelievably stupid degenerate case of r == -0.


    if (r.digits[0] == 0 && this.biHighIndex(r) == 0) r.isNeg = false;
    return new Array(q, r);
  };

  BigInt.biDivide = function (x, y, bl) {
    return this.biDivideModulo(x, y, bl)[0];
  };

  BigInt.biModuloByRadixPower = function (x, n, bl) {
    var result = new BigInt(bl);
    this.arrayCopy(x.digits, 0, result.digits, 0, n);
    return result;
  };

  BigInt.biDivideByRadixPower = function (x, n, bl) {
    var result = new BigInt(bl);
    this.arrayCopy(x.digits, n, result.digits, 0, result.digits.length - n);
    return result;
  };

  BigInt.biMultiply = function (x, y, bl) {
    var result = new BigInt(bl);
    var c;
    var n = this.biHighIndex(x);
    var t = this.biHighIndex(y);
    var uv, k;

    for (var i = 0; i <= t; ++i) {
      c = 0;
      k = i;

      for (var j = 0; j <= n; ++j, ++k) {
        uv = result.digits[k] + x.digits[j] * y.digits[i] + c;
        result.digits[k] = uv & maxDigitVal;
        c = uv >>> biRadixBits;
      }

      result.digits[i + n + 1] = c;
    } // Someone give me a logical xor, please.


    result.isNeg = x.isNeg != y.isNeg;
    return result;
  };

  return BigInt;
}();

var BarrettMu =
/** @class */
function () {
  function BarrettMu(m, bl) {
    this.modulus = BigInt.biCopy(m);
    this.k = BigInt.biHighIndex(this.modulus) + 1;
    var b2k = new BigInt(bl);
    b2k.digits[2 * this.k] = 1; // b2k = b^(2k)

    this.mu = BigInt.biDivide(b2k, this.modulus, bl);
    this.bkplus1 = new BigInt(bl);
    this.bkplus1.digits[this.k + 1] = 1; // bkplus1 = b^(k+1)
  }

  BarrettMu.prototype.BarrettMu_modulo = function (x, bl) {
    var q1 = BigInt.biDivideByRadixPower(x, this.k - 1, bl);
    var q2 = BigInt.biMultiply(q1, this.mu, bl);
    var q3 = BigInt.biDivideByRadixPower(q2, this.k + 1, bl);
    var r1 = BigInt.biModuloByRadixPower(x, this.k + 1, bl);
    var r2term = BigInt.biMultiply(q3, this.modulus, bl);
    var r2 = BigInt.biModuloByRadixPower(r2term, this.k + 1, bl);
    var r = BigInt.biSubtract(r1, r2, bl);

    if (r.isNeg) {
      r = BigInt.biAdd(r, this.bkplus1, bl);
    }

    var rgtem = BigInt.biCompare(r, this.modulus) >= 0;

    while (rgtem) {
      r = BigInt.biSubtract(r, this.modulus, bl);
      rgtem = BigInt.biCompare(r, this.modulus) >= 0;
    }

    return r;
  };

  BarrettMu.prototype.BarrettMu_multiplyMod = function (x, y, bl) {
    var xy = BigInt.biMultiply(x, y, bl);
    return this.BarrettMu_modulo(xy, bl);
  };

  BarrettMu.prototype.BarrettMu_powMod = function (x, y, bl) {
    var result = new BigInt(bl);
    result.digits[0] = 1;
    var a = x;
    var k = y;

    while (true) {
      if ((k.digits[0] & 1) !== 0) {
        result = this.BarrettMu_multiplyMod(result, a, bl);
      }

      k = BigInt.biShiftRight(k, 1, bl);

      if (k.digits[0] === 0 && BigInt.biHighIndex(k) === 0) {
        break;
      }

      a = this.BarrettMu_multiplyMod(a, a, bl);
    }

    return result;
  };

  return BarrettMu;
}();

var RSAAPP =
/** @class */
function () {
  function RSAAPP() {
    this.NoPadding = 'NoPadding';
    this.PKCS1Padding = 'PKCS1Padding';
    this.RawEncoding = 'RawEncoding';
    this.NumericEncoding = 'NumericEncoding';
  }

  RSAAPP.prototype.RSAKeyPair = function (encryptionExponent, decryptionExponent, modulus, keylen, bl) {
    this.BigInt = new BigInt(bl);
    this.e = BigInt.biFromHex(encryptionExponent, bl);
    this.d = BigInt.biFromHex(decryptionExponent, bl);
    this.m = BigInt.biFromHex(modulus, bl);

    if (typeof keylen !== 'number') {
      this.chunkSize = 2 * BigInt.biHighIndex(this.m);
    } else {
      this.chunkSize = keylen / 8;
    }

    this.radix = 16;
    this.barrett = new BarrettMu(this.m, bl);
  };

  RSAAPP.prototype.encryptedString = function (key, s, pad, encoding, bl) {
    var a = new Array();
    var sl = s.length;
    var i, j, k;
    var padtype;
    var encodingtype;
    var rpad;
    var al;
    var result = '';
    var block;
    var crypt;
    var text;

    if (typeof pad === 'string') {
      if (pad === this.NoPadding) {
        padtype = 1;
      } else if (pad === this.PKCS1Padding) {
        padtype = 2;
      } else {
        padtype = 0;
      }
    } else {
      padtype = 0;
    }

    if (typeof encoding === 'string' && encoding === this.RawEncoding) {
      encodingtype = 1;
    } else {
      encodingtype = 0;
    }

    if (padtype === 1) {
      if (sl > key.chunkSize) {
        sl = key.chunkSize;
      }
    } else if (padtype === 2) {
      if (sl > key.chunkSize - 11) {
        sl = key.chunkSize - 11;
      }
    }

    i = 0;

    if (padtype === 2) {
      j = sl - 1;
    } else {
      j = key.chunkSize - 1;
    }

    while (i < sl) {
      if (padtype) {
        a[j] = s.charCodeAt(i);
      } else {
        a[i] = s.charCodeAt(i);
      }

      i++;
      j--;
    }

    if (padtype === 1) {
      i = 0;
    }

    j = key.chunkSize - sl % key.chunkSize;

    while (j > 0) {
      if (padtype === 2) {
        // rpad = Math.floor(Math.random() * 256);
        rpad = 1;

        while (!rpad) {
          // rpad = Math.floor(Math.random() * 256);
          rpad = 1;
        }

        a[i] = rpad;
      } else {
        a[i] = 0;
      }

      i++;
      j--;
    }

    if (padtype === 2) {
      a[sl] = 0;
      a[key.chunkSize - 2] = 2;
      a[key.chunkSize - 1] = 0;
    }

    al = a.length;

    for (i = 0; i < al; i += key.chunkSize) {
      block = new BigInt(bl);
      j = 0;

      for (k = i; k < i + key.chunkSize; ++j) {
        block.digits[j] = a[k++];
        block.digits[j] += a[k++] << 8;
      }

      crypt = key.barrett.BarrettMu_powMod(block, key.e, bl);

      if (encodingtype === 1) {
        text = BigInt.biToBytes(crypt);
      } else {
        text = key.radix === 16 ? BigInt.biToHex(crypt) : BigInt.biToString(crypt, key.radix, bl);
      }

      result += text;
    }

    return result;
  };

  return RSAAPP;
}();

var rsa = new RSAAPP();
exports["default"] = rsa;