"use strict";

var _interopRequireWildcard = require("/home/gitlab-runner/builds/qGtedu_k/0/root/art_nft/dev-web/vue-admin/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var common = _interopRequireWildcard(require("@/utils/common"));

var _default = {
  inserted: function inserted(el, binding, vnode) {
    if (binding.value === undefined) return;
    var value = binding.value;

    if (value && value instanceof Array && value.length > 0) {
      if (!common.acl(value)) {
        el.parentNode && el.parentNode.removeChild(el);
      }
    } else {
      throw new Error("need roles! Like v-acl=\"['route']\"");
    }
  }
};
exports.default = _default;