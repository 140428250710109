"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = [{
  path: 'list',
  component: function component() {
    return import("./list");
  },
  meta: {
    title: '作品列表',
    keep: true
  }
}, {
  path: 'form',
  component: function component() {
    return import("./form");
  },
  meta: {
    title: '上传作品'
  }
}, {
  path: 'issue',
  component: function component() {
    return import("./issue");
  },
  meta: {
    title: '作品发布'
  }
}, {
  path: 'detail',
  component: function component() {
    return import("./form-comp/detail");
  },
  meta: {
    title: '作品详情'
  }
}, {
  path: 'nft_list',
  component: function component() {
    return import("./nft-list");
  },
  meta: {
    title: 'NFT列表',
    keep: true
  }
}, {
  path: 'nft_show',
  component: function component() {
    return import("./form-comp/nft-show");
  },
  meta: {
    title: 'NFT详情'
  }
}, {
  path: 'series',
  component: function component() {
    return import("./series");
  },
  meta: {
    title: '系列列表',
    keep: true
  }
}, {
  path: 'series_form',
  component: function component() {
    return import("./series-form");
  },
  meta: {
    title: '编辑系列'
  }
}];
exports.default = _default;