"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/qGtedu_k/0/root/art_nft/dev-web/vue-admin/node_modules/@babel/runtime-corejs2/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _http = _interopRequireDefault(require("@/utils/http"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      init: true,
      limit: 0,
      code: ''
    };
  },
  computed: {
    smsButtonName: function smsButtonName() {
      if (this.init) {
        return "发送验证码";
      } else if (this.limit > 0) {
        return this.limit + '秒后重发';
      } else {
        return '重发短信';
      }
    },
    smsLoading: function smsLoading() {
      return this.limit > 0;
    }
  },
  methods: {
    sendSMS: function sendSMS() {
      var _this = this;

      _http.default.axios({
        url: 'second_verify/sms'
      }).then(function () {
        _this.limit = 60;
        _this.init = false;
        var interval = setInterval(function () {
          _this.limit--;

          if (_this.limit <= 0) {
            clearInterval(interval);
          }
        }, 1000);
      });
    },
    close: function close(uri, action) {
      var _this2 = this;

      return new Promise(function (resolve, reject) {
        if (action) {
          _http.default.axios({
            url: 'second_verify/verify',
            data: {
              code: _this2.code,
              uri: uri
            },
            method: 'POST'
          }).then(function (res) {
            return resolve(res.data);
          }).catch(reject).finally(_this2.reset);
        } else {
          _this2.reset();

          reject();
        }
      });
    },
    reset: function reset() {
      this.limit = 0;
      this.code = '';
      this.init = true;
    }
  }
};
exports.default = _default;