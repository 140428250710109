"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = [{
  path: 'list',
  component: function component() {
    return import("./list");
  },
  meta: {
    title: '订单列表',
    keep: true
  }
}, {
  path: 'mystery-box-list',
  component: function component() {
    return import("./mystery-box/list");
  },
  meta: {
    title: '盲盒订单列表',
    keep: true
  }
}, {
  path: 'mystery-box-detail',
  component: function component() {
    return import("./mystery-box/detail");
  },
  meta: {
    title: '盲盒订单详情'
  }
}, {
  path: 'form',
  component: function component() {
    return import("./form");
  },
  meta: {
    title: '订单详情'
  }
}, {
  path: 'invoice-form',
  component: function component() {
    return import("./invoice-form");
  },
  meta: {
    title: '开票详情'
  }
}, {
  path: 'pre_sale_list',
  component: function component() {
    return import("./pre-sale-list");
  },
  meta: {
    title: '预售支付记录列表'
  }
}, {
  path: 'hanging_sale_list',
  component: function component() {
    return import("./hanging-sale-list");
  },
  meta: {
    title: '寄售列表'
  }
}, {
  path: 'invoice_list',
  component: function component() {
    return import("./invoice-list");
  },
  meta: {
    title: '开票记录'
  }
}];
exports.default = _default;