"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = [{
  path: 'password',
  component: function component() {
    return import("./password.vue");
  },
  meta: {
    title: '修改密码'
  }
}, {
  path: 'phone',
  component: function component() {
    return import("./phone.vue");
  },
  meta: {
    title: '修改手机'
  }
}, {
  path: 'preset',
  component: function component() {
    return import("./pre-set.vue");
  },
  meta: {
    title: '修改预留信息'
  }
}];
exports.default = _default;