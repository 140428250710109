"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/qGtedu_k/0/root/art_nft/dev-web/vue-admin/node_modules/@babel/runtime-corejs2/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.resetRouter = resetRouter;
exports.default = exports.constantRoutes = void 0;

require("core-js/modules/es6.function.name");

require("core-js/modules/es6.regexp.replace");

var _toConsumableArray2 = _interopRequireDefault(require("/home/gitlab-runner/builds/qGtedu_k/0/root/art_nft/dev-web/vue-admin/node_modules/@babel/runtime-corejs2/helpers/esm/toConsumableArray"));

var _vue = _interopRequireDefault(require("vue"));

var _vueRouter = _interopRequireDefault(require("vue-router"));

var _layout = _interopRequireDefault(require("@/layout"));

var _common = require("@/utils/common");

_vue.default.use(_vueRouter.default);
/* Layout */


var constantRoutes = [{
  path: '/login',
  component: function component() {
    return import('@/views/login/index2');
  },
  meta: {
    auth: false
  }
}, {
  path: '/',
  component: _layout.default,
  redirect: '/home',
  children: [{
    path: 'home',
    component: function component() {
      return import('@/views/dashboard/index');
    },
    meta: {
      title: '首页'
    }
  }].concat((0, _toConsumableArray2.default)(require('@/views/router.js').default), [// 改为自动导入view下一级目录中的router.js 避免此页面在协同合作时冲突
  {
    path: '/redirect/:path*',
    hidden: true,
    component: function component() {
      return import('@/views/redirect');
    },
    meta: {
      auth: false
    }
  }])
}, {
  path: '*',
  redirect: 'exception/404'
}];
exports.constantRoutes = constantRoutes;

var generateRoutes = function generateRoutes(routes) {
  return routes.map(function (route) {
    route = (0, _common.deepCopy)(route);

    if (!route.hidden) {
      if (route.children) {
        route.children = generateRoutes(route.children);
      } else if (typeof route.component === 'function') {
        route.name = route.path.replace(/^\//, '').replace(/[\/:]/g, '__');
        var comp = route.component();

        route.component = function () {
          return comp.then(function (comp) {
            comp.default.name = route.name;
            return comp;
          });
        };
      }
    }

    return route;
  });
};

var createRouter = function createRouter(r) {
  if (!r) r = constantRoutes;
  var routes = generateRoutes(r);
  var router = new _vueRouter.default({
    // mode: 'history', // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: routes
  });
  router._routes = routes;
  return router;
};

var router = createRouter(); // Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465

function resetRouter(r) {
  var newRouter = createRouter(r);
  router.matcher = newRouter.matcher; // reset router
}

var _default = router;
exports.default = _default;