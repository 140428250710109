"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = [{
  path: 'list',
  component: function component() {
    return import("./list");
  },
  meta: {
    title: '消息列表'
  }
}, {
  path: 'view',
  component: function component() {
    return import("./detail");
  },
  meta: {
    title: '查看消息'
  }
}, {
  path: 'form',
  component: function component() {
    return import("./form");
  },
  meta: {
    title: '消息新增&编辑'
  }
}];
exports.default = _default;