"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = [{
  path: 'list',
  component: function component() {
    return import("./list");
  },
  meta: {
    title: '活动列表',
    keep: true
  }
}, {
  path: 'pre_sale_form',
  component: function component() {
    return import("./form/pre-sale");
  },
  meta: {
    title: '预售活动设置'
  }
}, {
  path: 'combination_form',
  component: function component() {
    return import("./form/combination");
  },
  meta: {
    title: '合成规则设置'
  }
}, {
  path: 'draw_form',
  component: function component() {
    return import("./form/draw");
  },
  meta: {
    title: '抽签活动设置'
  }
}, {
  path: 'airdrop_form',
  component: function component() {
    return import("./form/airdrop");
  },
  meta: {
    title: '空投活动设置'
  }
}, {
  path: 'prize_wheel_form',
  component: function component() {
    return import("./form/prize-wheel");
  },
  meta: {
    title: '转盘抽奖设置'
  }
}, {
  path: 'prize_wheel_records',
  component: function component() {
    return import("./records/prize-wheel");
  },
  meta: {
    title: '转盘抽奖结果记录'
  }
}, {
  path: 'integral_form',
  component: function component() {
    return import("./form/integral");
  },
  meta: {
    title: '签到活动设置'
  }
}, {
  path: 'integral_records',
  component: function component() {
    return import("./records/integral");
  },
  meta: {
    title: '签到活动记录'
  }
}];
exports.default = _default;