"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.replace");

var _validate = require("@/utils/validate");

//
//
//
//
//
//
//
var _default = {
  props: {
    to: {
      type: String,
      required: true
    }
  },
  methods: {
    url: function url() {
      if (this.$route.path === this.to && !this.$store.state.settings.tagsView) {
        // 开启tagview时点击当前菜单不进入
        this.$router.replace({
          path: '/redirect' + this.to
        });
      } else {
        this.$router.push({
          path: this.to
        });
      }
    }
  }
};
exports.default = _default;