"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = [{
  path: '404',
  component: function component() {
    return import("./404");
  },
  meta: {
    title: '404'
  }
}, {
  path: '403',
  component: function component() {
    return import("./403");
  },
  meta: {
    title: '403'
  }
}];
exports.default = _default;