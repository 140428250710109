"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/qGtedu_k/0/root/art_nft/dev-web/vue-admin/node_modules/@babel/runtime-corejs2/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _toConsumableArray2 = _interopRequireDefault(require("/home/gitlab-runner/builds/qGtedu_k/0/root/art_nft/dev-web/vue-admin/node_modules/@babel/runtime-corejs2/helpers/esm/toConsumableArray"));

var _common = require("@/utils/common");

var _default = [{
  path: 'settings',
  component: function component() {
    return import("./settings/index.vue");
  },
  meta: {
    title: '个人中心'
  }
}].concat((0, _toConsumableArray2.default)((0, _common.moduleRoutes)('security', require("./settings/security/router.js"))));

exports.default = _default;