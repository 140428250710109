"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/qGtedu_k/0/root/art_nft/dev-web/vue-admin/node_modules/@babel/runtime-corejs2/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _createForOfIteratorHelper2 = _interopRequireDefault(require("/home/gitlab-runner/builds/qGtedu_k/0/root/art_nft/dev-web/vue-admin/node_modules/@babel/runtime-corejs2/helpers/esm/createForOfIteratorHelper"));

var _index = require("@/lang/index");

var _storage = _interopRequireDefault(require("@/utils/storage"));

var _common = require("@/utils/common");

var state = {
  sidebar: {
    opened: _storage.default.get('sidebarStatus') ? !!+_storage.default.get('sidebarStatus') : true,
    withoutAnimation: false
  },
  device: 'desktop',
  language: (0, _index.getLanguage)(),
  menu: [],
  mapMenu: {},
  // menu url:item 映射关系
  permission: [],
  role: [],
  rules: []
};
var mutations = {
  TOGGLE_SIDEBAR: function TOGGLE_SIDEBAR(state) {
    state.sidebar.opened = !state.sidebar.opened;
    state.sidebar.withoutAnimation = false;

    if (state.sidebar.opened) {
      _storage.default.set('sidebarStatus', 1);
    } else {
      _storage.default.set('sidebarStatus', 0);
    }
  },
  CLOSE_SIDEBAR: function CLOSE_SIDEBAR(state, withoutAnimation) {
    _storage.default.set('sidebarStatus', 0);

    state.sidebar.opened = false;
    state.sidebar.withoutAnimation = withoutAnimation;
  },
  TOGGLE_DEVICE: function TOGGLE_DEVICE(state, device) {
    state.device = device;
  },
  SET_LANGUAGE: function SET_LANGUAGE(state, language) {
    state.language = language;

    _storage.default.set('language', language);
  },
  SET_MENU: function SET_MENU(state, menu) {
    state.menu = menu;
    var map = {};

    var f = function f(menus) {
      var _iterator = (0, _createForOfIteratorHelper2.default)(menus),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var _menu = _step.value;

          if (_menu.children && _menu.children.length) {
            _menu.children = f(_menu.children);
          } else {
            map[_menu.url] = _menu;
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      return menus;
    };

    f((0, _common.deepCopy)(menu));
    state.mapMenu = map;
  },
  SET_PERMISSION: function SET_PERMISSION(state, permission) {
    state.permission = permission;
  },
  SET_ROLE: function SET_ROLE(state, role) {
    state.role = role;
  },
  SET_RULES: function SET_RULES(state, rules) {
    state.rules = rules;
  }
};
var actions = {
  toggleSideBar: function toggleSideBar(_ref) {
    var commit = _ref.commit;
    commit('TOGGLE_SIDEBAR');
  },
  closeSideBar: function closeSideBar(_ref2, _ref3) {
    var commit = _ref2.commit;
    var withoutAnimation = _ref3.withoutAnimation;
    commit('CLOSE_SIDEBAR', withoutAnimation);
  },
  toggleDevice: function toggleDevice(_ref4, device) {
    var commit = _ref4.commit;
    commit('TOGGLE_DEVICE', device);
  },
  setLanguage: function setLanguage(_ref5, language) {
    var commit = _ref5.commit;
    commit('SET_LANGUAGE', language);
  },
  setMenu: function setMenu(_ref6, menu) {
    var commit = _ref6.commit;
    commit('SET_MENU', menu);
  },
  setPermission: function setPermission(_ref7, permission) {
    var commit = _ref7.commit;
    commit('SET_PERMISSION', permission);
  },
  setRole: function setRole(_ref8, role) {
    var commit = _ref8.commit;
    commit('SET_ROLE', role);
  },
  setRules: function setRules(_ref9, rules) {
    var commit = _ref9.commit;
    commit('SET_RULES', rules);
  }
};
var _default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};
exports.default = _default;