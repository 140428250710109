"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

/**
 * 数据持久化
 */
var _default = {
  //存储
  set: function set(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  },
  //取出数据v
  get: function get(key, def) {
    var v = localStorage.getItem(key);

    try {
      v = JSON.parse(v);
    } catch (_unused) {
      v = def;
    }

    return v;
  },
  // 删除数据
  remove: function remove(key) {
    localStorage.removeItem(key);
  }
};
exports.default = _default;