"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  install: function install(Vue) {
    // 全局注册的组件
    Vue.component('svg-icon', function () {
      return import("./SvgIcon");
    });
    Vue.component('f-dialog', function () {
      return import("./FDialog");
    });
    Vue.component('export-button', function () {
      return import("./EasyExport/ExportButton");
    });
    Vue.component('web-draft', function () {
      return import("./WebDraft");
    });
  }
};
exports.default = _default;