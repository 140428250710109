"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = [{
  path: 'list',
  component: function component() {
    return import("./list");
  },
  meta: {
    title: '盲盒列表',
    keep: true
  }
}, {
  path: 'form',
  component: function component() {
    return import("./form");
  },
  meta: {
    title: '编辑盲盒'
  }
}, {
  path: 'issue',
  component: function component() {
    return import("./components/issue");
  },
  meta: {
    title: '常规模式'
  }
}];
exports.default = _default;