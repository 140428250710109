"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

var getters = {
  sidebar: function sidebar(state) {
    return state.app.sidebar;
  },
  language: function language(state) {
    return state.app.language;
  },
  menu: function menu(state) {
    return state.app.menu;
  },
  permission: function permission(state) {
    return state.app.permission;
  },
  role: function role(state) {
    return state.app.role;
  },
  rules: function rules(state) {
    return state.app.rules;
  },
  device: function device(state) {
    return state.app.device;
  },
  visitedViews: function visitedViews(state) {
    return state.tagsView.visitedViews;
  },
  cachedViews: function cachedViews(state) {
    return state.tagsView.cachedViews;
  },
  token: function token(state) {
    return state.user.token;
  },
  avatar: function avatar(state) {
    return state.user.avatar;
  },
  copyright_warning: function copyright_warning(state) {
    return state.user.copyright_warning;
  },
  phone: function phone(state) {
    return state.user.phone;
  },
  name: function name(state) {
    return state.user.name;
  },
  nickname: function nickname(state) {
    return state.user.nickname;
  },
  account_level: function account_level(state) {
    return state.user.account_level;
  },
  last_login_ip: function last_login_ip(state) {
    return state.user.last_login_ip;
  },
  last_login_time: function last_login_time(state) {
    return state.user.last_login_time;
  },
  publicKey: function publicKey(state) {
    return state.user.publicKey;
  },
  preSet: function preSet(state) {
    return state.user.preSet;
  },
  openid: function openid(state) {
    return state.user.openid;
  },
  dict: function dict(state, getters) {
    return state.dict.dict[getters.language] || {};
  }
};
var _default = getters;
exports.default = _default;