"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/qGtedu_k/0/root/art_nft/dev-web/vue-admin/node_modules/@babel/runtime-corejs2/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.match");

require("core-js/modules/web.dom.iterable");

var _createForOfIteratorHelper2 = _interopRequireDefault(require("/home/gitlab-runner/builds/qGtedu_k/0/root/art_nft/dev-web/vue-admin/node_modules/@babel/runtime-corejs2/helpers/esm/createForOfIteratorHelper"));

var viewRoutes = [];

var contexts = require.context('.', true, /router.js$/);

var _iterator = (0, _createForOfIteratorHelper2.default)(contexts.keys()),
    _step;

try {
  for (_iterator.s(); !(_step = _iterator.n()).done;) {
    var routerPath = _step.value;
    var matchs = routerPath.match(/^\.\/([^\/]+?)\/router\.js$/);

    if (matchs) {
      var prefix = matchs[1];

      var _iterator2 = (0, _createForOfIteratorHelper2.default)(contexts(routerPath).default),
          _step2;

      try {
        for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
          var item = _step2.value;
          item.path = "".concat(prefix, "/").concat(item.path);
          viewRoutes.push(item);
        }
      } catch (err) {
        _iterator2.e(err);
      } finally {
        _iterator2.f();
      }
    }
  }
} catch (err) {
  _iterator.e(err);
} finally {
  _iterator.f();
}

var _default = viewRoutes;
exports.default = _default;